import { required } from 'vuelidate/lib/validators';

export default {
    validations: {
        realization: {
            title: { required },
            description: {
                requiredWysiwyg: val => {
                    const regex = /(<([^>]+)>)/gi;

                    return val.replace(regex, '') !== '';
                }
            }
        },
        imageName: { required },
        technologyName: {
            isPresent: function() {
                return this.technologies.length !== 0;
            }
        }
    },
    computed: {
        titleErrors() {
            const errors = [];
            if (!this.$v.realization.title.$dirty) return errors;
            !this.$v.realization.title.required &&
                errors.push('Title is required.');

            return errors;
        },

        descriptionErrors() {
            const errors = [];
            if (!this.$v.realization.description.$dirty) return errors;
            !this.$v.realization.description.requiredWysiwyg &&
                errors.push('Description is required.');

            return errors;
        },

        imageErrors() {
            const errors = [];
            if (!this.$v.imageName.$dirty) return errors;
            !this.$v.imageName.required && errors.push('Image is required.');

            return errors;
        },

        technologiesErrors() {
            const errors = [];
            if (!this.$v.technologyName.$dirty) return errors;
            !this.$v.technologyName.isPresent &&
                errors.push('Please add at least one technology.');

            return errors;
        }
    }
};
