<template>
    <v-dialog v-model="isOpen" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">{{
                    isEditMode ? 'Edit realization' : 'Add realization'
                }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="realization.title"
                                label="Title"
                                :error-messages="titleErrors"
                                @input="$v.realization.title.$touch"
                                @blur="$v.realization.title.$touch"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <wysiwyg-editor
                                v-model="realization.description"
                                label="Description"
                                :key="realization.id"
                                :error-border="descriptionErrors.length !== 0"
                            />
                        </v-col>
                        <template v-if="realization.image && isEditMode">
                            <v-col cols="12" align="center" justify="center">
                                <img
                                    :src="imageUrl"
                                    max-height="150"
                                    style="background-color: #eee;"
                                />
                            </v-col>
                            <v-col cols="12" align="center" justify="center">
                                <v-icon @click="deleteImage">delete</v-icon>
                            </v-col>
                        </template>
                        <v-col cols="12">
                            <div class="p-3 bg-white shadow rounded-lg">
                                <v-text-field
                                    :error-messages="imageErrors"
                                    :value="imageName"
                                    label="Image"
                                    prepend-icon="attach_file"
                                    readonly="readonly"
                                    @blur="$v.imageName.$touch"
                                    @click="pickImage"
                                    @input="$v.imageName.$touch"
                                />
                                <input
                                    ref="image"
                                    accept="image/*"
                                    name="image"
                                    style="display: none"
                                    type="file"
                                    @change="setImage"
                                />
                                <div
                                    v-if="imageSrc"
                                    class="my-3 d-flex align-items-center justify-content-center mx-auto"
                                >
                                    <vue-cropper
                                        ref="cropper"
                                        :aspectRatio="54 / 37"
                                        :autoCrop="true"
                                        :autoCropArea="1"
                                        :guides="true"
                                        :initialAspectRatio="54 / 37"
                                        :src="imageSrc"
                                        :viewMode="1"
                                        class="mr-2 w-50"
                                    />
                                </div>
                                <v-btn
                                    v-if="imageSrc"
                                    class="mr-2"
                                    small
                                    @click="cropImage"
                                    >Crop</v-btn
                                >
                                <v-btn
                                    v-if="imageSrc"
                                    class="mr-2"
                                    small
                                    @click="onImageUpload"
                                    >Upload</v-btn
                                >
                                <v-btn
                                    v-if="imageSrc"
                                    class="mr-2"
                                    small
                                    @click="resetCrop"
                                    >Reset</v-btn
                                >
                                <v-btn
                                    v-if="imageSrc"
                                    small
                                    @click="deleteImage"
                                    >Delete</v-btn
                                >
                            </div>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col cols="6">
                            <v-text-field
                                v-model="technologyName"
                                label="Technology name"
                                :error-messages="technologiesErrors"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="success" block @click="addTechnology"
                                >Add technology</v-btn
                            >
                        </v-col>
                        <v-col v-if="technologies.length" cols="12">
                            <v-simple-table class="text-center">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-center">Name</th>
                                            <th class="text-center">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(technology,
                                            index) in technologies"
                                            :key="index"
                                        >
                                            <td>{{ technology }}</td>
                                            <td>
                                                <v-icon
                                                    @click="
                                                        deleteTechnology(index)
                                                    "
                                                    >delete</v-icon
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeModal()"
                    >Close</v-btn
                >
                <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import { mapActions } from 'vuex';
import RealizationValidation from './realization-validation.mixin';
import ImageCrop from '@/mixins/imageCrop.mixin';
import WysiwygEditor from '@/components/elements/WysiwygEditor';

export default {
    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },

        isEditMode: {
            type: Boolean,
            required: true,
            default: false
        },

        realizationToEdit: {
            type: Object,
            required: false,
            default: () => {}
        }
    },

    components: {
        WysiwygEditor,
        VueCropper
    },

    mixins: [RealizationValidation, ImageCrop],

    computed: {
        imageUrl() {
            if (!this.realization.image) {
                return '';
            }

            const backendUrl = process.env.VUE_APP_MEDIA_URL;
            const isSafari = /^((?!chrome|android).)*safari/i.test(
                navigator.userAgent
            );
            const isWebSupported = !isSafari;

            return `${backendUrl}/realizations/${this.realization.image}.${
                isWebSupported ? 'webp' : 'jpg'
            }`;
        }
    },

    watch: {
        isEditMode() {
            if (this.isEditMode) {
                this.realization = { ...this.realizationToEdit };
                this.technologies = [...this.realizationToEdit.technologies];
                this.imageName = this.realizationToEdit.image;
            }
        },

        uploadedImage() {
            this.realization.image = this.uploadedImage;
        }
    },

    data: () => ({
        realization: {},
        technologies: [],
        technologyName: ''
    }),

    methods: {
        ...mapActions({
            uploadImage: 'realizations/uploadImage',
            addRealization: 'realizations/addRealization',
            updateRealization: 'realizations/updateRealization'
        }),

        async save() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                if (this.isEditMode) {
                    const realization = await this.updateRealization({
                        id: this.realizationToEdit.id,
                        realizationData: this.realization,
                        technologies: this.technologies
                    });

                    this.$emit('updated', realization);
                    this.$toasted.success('Realization has been updated!');
                } else {
                    const realization = await this.addRealization({
                        realizationData: this.realization,
                        technologies: this.technologies
                    });

                    this.$emit('added', realization);
                    this.$toasted.success('Realization has been added!');
                }

                this.deleteImage();
                this.realization = {};
                this.technologies = [];
                this.$v.$reset();
            } catch (err) {
                console.error(err);

                this.$toasted.error('Something went wrong!');
            }
        },

        closeModal() {
            this.$emit('close');
            this.deleteImage();
            this.realization = {};
            this.technologies = [];
            this.$v.$reset();
        },

        addTechnology() {
            if (this.technologyName) {
                this.technologies = [...this.technologies, this.technologyName];

                this.technologyName = '';
            }
        },

        deleteTechnology(index) {
            this.technologies.splice(index, 1);
        }
    }
};
</script>
