<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-btn color="success" @click="addRealizationModal = true">
                    Add Realization
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :class="`elevation-1 ${
                        disableHoverEffect ? 'remove-hover-effect' : ''
                    }`"
                    :key="addRealizationModal"
                    :headers="headers"
                    :items="realizations"
                    :items-per-page="10"
                    disable-sort
                    @pagination="onPaginationChange"
                >
                    <template v-slot:item.description="{ item }">
                        <span v-html="item.description" />
                    </template>
                    <template v-slot:item.technologies="{ item }">
                        <span
                            v-for="(technology, index) in item.technologies"
                            :key="index"
                        >
                            {{ technology }}
                            {{
                                index !== item.technologies.length - 1
                                    ? ', '
                                    : ''
                            }}
                        </span>
                    </template>
                    <template v-slot:body="{ items }">
                        <draggable
                            :list="items"
                            ghost-class="draggable--ghost"
                            handle=".draggable--handle"
                            tag="tbody"
                            @end="
                                changeOrder(
                                    items,
                                    pagination.page,
                                    pagination.itemsPerPage
                                )
                            "
                            @start="
                                drag = true;
                                disableHoverEffect = true;
                            "
                        >
                            <tr v-for="item in items" :key="item.id">
                                <td align="center">{{ item.id }}</td>
                                <td align="center">{{ item.title }}</td>
                                <td align="center" v-html="item.description" />
                                <td align="center">
                                    <span
                                        v-for="(
                                            technology, index
                                        ) in item.technologies"
                                        :key="index"
                                    >
                                        {{ technology }}
                                        {{
                                            index !==
                                            item.technologies.length - 1
                                                ? ', '
                                                : ''
                                        }}
                                    </span>
                                </td>
                                <td align="center">
                                    <v-icon @click="openEditModal(item)">
                                        edit
                                    </v-icon>
                                    <v-icon @click="openDeleteModal(item)">
                                        delete
                                    </v-icon>
                                </td>
                                <td align="center">
                                    <v-icon class="draggable--handle">
                                        mdi-arrow-all
                                    </v-icon>
                                </td>
                            </tr>
                        </draggable>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <add-edit-realization-modal
            :is-open="addRealizationModal"
            :is-edit-mode="!!realizationToEdit"
            :realization-to-edit="realizationToEdit"
            @added="addRealization"
            @updated="editRealization"
            @close="onClose"
        />
        <confirm-delete-modal
            :isOpen="!!realizationToDelete"
            @disagree="realizationToDelete = null"
            @agree="removeRealization"
        />
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import Draggable from 'vuedraggable';
import AddEditRealizationModal from '@/components/realizations/AddEditRealizationModal';
import ConfirmDeleteModal from '@/components/modals/ConfirmDeleteModal';
import ChangeOrder from '@/mixins/changeOrder.mixin';

export default {
    components: {
        AddEditRealizationModal,
        ConfirmDeleteModal,
        Draggable
    },

    mixins: [ChangeOrder],

    data: () => ({
        disableHoverEffect: false,
        pagination: {},
        realizations: [],
        total: 0,
        headers: [
            {
                text: 'Id',
                value: 'id',
                align: 'center'
            },
            { text: 'Title', value: 'title', align: 'center' },
            { text: 'Description', value: 'description', align: 'center' },
            { text: 'Technologies', value: 'technologies', align: 'center' },
            {
                text: 'Actions',
                value: 'actions',
                align: 'center'
            },
            {
                text: 'Position',
                align: 'center'
            }
        ],
        realizationToDelete: null,
        realizationToEdit: null,
        addRealizationModal: false
    }),

    async mounted() {
        try {
            await this.getTableItems();
        } catch (err) {
            console.error(err);

            this.$toasted.error('Something went wrong!');
        }
    },

    methods: {
        ...mapActions({
            getRealizations: 'realizations/getRealizations',
            deleteRealization: 'realizations/deleteRealization',
            updateOrder: 'realizations/updateOrder'
        }),

        onPaginationChange(pagination) {
            this.pagination = pagination;
        },

        async getTableItems() {
            const realizations = await this.getRealizations();

            this.realizations = realizations.rows;
            this.total = realizations.count;
        },

        addRealization(realization) {
            this.realizations = [realization, ...this.realizations];
            this.addRealizationModal = false;
        },

        openEditModal(realization) {
            this.realizationToEdit = realization;
            this.addRealizationModal = true;
        },

        editRealization(realization) {
            const editingIndex = this.realizations.findIndex(
                r => r.id === realization.id
            );

            if (editingIndex !== -1) {
                this.realizations[editingIndex] = { ...realization };
            }

            this.realizationToEdit = null;
            this.addRealizationModal = false;
        },

        openDeleteModal(realization) {
            this.realizationToDelete = realization;
        },

        async removeRealization() {
            try {
                await this.deleteRealization(this.realizationToDelete.id);

                const realizationIndex = this.realizations.findIndex(
                    realization => {
                        return realization.id === this.realizationToDelete.id;
                    }
                );

                this.realizations.splice(realizationIndex, 1);

                this.realizationToDelete = null;

                this.$toasted.success('Realization has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasted.error('Something went wrong!');
            }
        },

        onClose() {
            this.addRealizationModal = false;
            this.realizationToEdit = null;
        }
    }
};
</script>
