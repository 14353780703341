var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){_vm.addRealizationModal = true}}},[_vm._v(" Add Realization ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{key:_vm.addRealizationModal,class:("elevation-1 " + (_vm.disableHoverEffect ? 'remove-hover-effect' : '')),attrs:{"headers":_vm.headers,"items":_vm.realizations,"items-per-page":10,"disable-sort":""},on:{"pagination":_vm.onPaginationChange},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
                var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.description)}})]}},{key:"item.technologies",fn:function(ref){
                var item = ref.item;
return _vm._l((item.technologies),function(technology,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(technology)+" "+_vm._s(index !== item.technologies.length - 1 ? ', ' : '')+" ")])})}},{key:"body",fn:function(ref){
                var items = ref.items;
return [_c('draggable',{attrs:{"list":items,"ghost-class":"draggable--ghost","handle":".draggable--handle","tag":"tbody"},on:{"end":function($event){return _vm.changeOrder(
                                items,
                                _vm.pagination.page,
                                _vm.pagination.itemsPerPage
                            )},"start":function($event){_vm.drag = true;
                            _vm.disableHoverEffect = true;}}},_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.id))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.title))]),_c('td',{attrs:{"align":"center"},domProps:{"innerHTML":_vm._s(item.description)}}),_c('td',{attrs:{"align":"center"}},_vm._l((item.technologies),function(technology,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(technology)+" "+_vm._s(index !== item.technologies.length - 1 ? ', ' : '')+" ")])}),0),_c('td',{attrs:{"align":"center"}},[_c('v-icon',{on:{"click":function($event){return _vm.openEditModal(item)}}},[_vm._v(" edit ")]),_c('v-icon',{on:{"click":function($event){return _vm.openDeleteModal(item)}}},[_vm._v(" delete ")])],1),_c('td',{attrs:{"align":"center"}},[_c('v-icon',{staticClass:"draggable--handle"},[_vm._v(" mdi-arrow-all ")])],1)])}),0)]}}])})],1)],1),_c('add-edit-realization-modal',{attrs:{"is-open":_vm.addRealizationModal,"is-edit-mode":!!_vm.realizationToEdit,"realization-to-edit":_vm.realizationToEdit},on:{"added":_vm.addRealization,"updated":_vm.editRealization,"close":_vm.onClose}}),_c('confirm-delete-modal',{attrs:{"isOpen":!!_vm.realizationToDelete},on:{"disagree":function($event){_vm.realizationToDelete = null},"agree":_vm.removeRealization}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }